import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import Form from '@thrivetrm/ui/components/Form';
import FormDropzone from 'core/components/Form.Dropzone';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import { isValidEmailAddress } from '../forms/validators/emailAddress';
import { useApiGet } from '@thrivetrm/ui/hooks/useApi';
import formDataPropType from '../../propTypes/formDataPropType';
import { formatPrivacyPolicyUrl } from '../../util';

const MAX_RESUME_FILE_SIZE_MB = 7.5;
const KB = 1024;
const BYTES_IN_MB = KB * KB;

const ApplyProfessionalInfoForm = ({
  caslCompanyName,
  caslPrivacyPolicyUrl,
  formData,
  hasVoluntaryIdentificationStatusEnabled,
  isApplicationLoading,
  onClose,
  onSubmit,
}) => {
  const [
    loadContactSources,
    isLoadingContactSources,
    _contactSourcesLoadError,
    contactSources,
  ] = useApiGet('/api/v1/contact_sources');

  useEffect(() => {
    loadContactSources();
  }, [loadContactSources]);

  const applyConsentText = useSelector((state) =>
    state.customer.get('applyConsentText'),
  );

  const isCaslActive = useSelector((state) =>
    state.customer.getIn(['flags', 'casl']),
  );

  const contactSourceOptions = contactSources?.contact_sources.map(
    (contactSource) => ({
      label: contactSource.name,
      value: contactSource.id,
    }),
  );

  const contactSourcesWithDetailsRequired =
    contactSources?.contact_sources.filter(
      (contactSource) => contactSource.details === true,
    );

  const privacyUrl = formatPrivacyPolicyUrl(caslPrivacyPolicyUrl);

  const validateEmailAddress = (emailAddress) =>
    isValidEmailAddress(emailAddress)
      ? null
      : 'Please enter a valid email address';

  const validateConsent = (consent) =>
    isCaslActive && !consent ? 'Your consent is required' : null;

  const [resumeError, setResumeError] = useState(null);

  const validateResumeFileSize = (event) => {
    const { files } = event.target;
    if (files?.[0]?.size > MAX_RESUME_FILE_SIZE_MB * BYTES_IN_MB) {
      setResumeError(
        `Resume file size must be less than ${MAX_RESUME_FILE_SIZE_MB} MB`,
      );
    } else {
      setResumeError(null);
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <LoadingContainer isLoading={isLoadingContactSources} minHeight='100%'>
        <Form.TextInput
          className='u-marginBottom-12'
          initialValue={formData.firstName}
          inputWidth='full'
          label='First Name'
          name='firstName'
          rules={{
            required: {
              value: true,
              message: 'First Name is required',
            },
          }}
        />

        <Form.TextInput
          className='u-marginBottom-12'
          initialValue={formData.lastName}
          inputWidth='full'
          label='Last Name'
          name='lastName'
          rules={{
            required: {
              value: true,
              message: 'Last Name is required',
            },
          }}
        />

        <Form.TextInput
          className='u-marginBottom-12'
          initialValue={formData.email}
          inputWidth='full'
          label='Email Address'
          name='email'
          rules={{
            validate: validateEmailAddress,
            required: {
              value: true,
              message: 'Email is required',
            },
          }}
        />

        <Form.TextInput
          className='u-marginBottom-12'
          initialValue={formData.phone}
          inputWidth='full'
          label='Mobile Phone'
          name='phone'
        />

        {contactSourceOptions?.length > 0 ? (
          <Form.Select
            className='u-marginBottom-12'
            data-testid='contact-source-input'
            initialValue={formData.contactSourceId}
            inputWidth='full'
            label='How did you hear about this job?'
            name='contactSourceId'
            options={contactSourceOptions}
            rules={{
              required: { value: true, message: 'Referral is required' },
            }}
            size='medium'
          />
        ) : null}

        <Form.FieldDependentContent
          shouldRender={(fields) =>
            contactSourcesWithDetailsRequired
              ?.map((source) => source.id)
              .includes(fields.contactSourceId)
          }
        >
          <Form.TextInput
            className='u-marginBottom-12'
            initialValue={formData.sourceDetails}
            inputWidth='full'
            label='Who referred you?'
            name='sourceDetails'
            rules={{
              required: {
                value: true,
                message: 'Referred by is required',
              },
            }}
          />
        </Form.FieldDependentContent>

        <Form.TextInput
          className='u-marginBottom-12'
          initialValue={formData.linkedInUrl}
          inputWidth='full'
          label='LinkedIn Public Profile URL'
          name='linkedInUrl'
        />

        <div className='u-marginTop-16'>
          <div className='row'>
            <div className='col-sm-6'>
              <FormDropzone
                initialValue={formData.resume}
                label='Resume/CV'
                name='resume'
                onChange={validateResumeFileSize}
                rules={{
                  required: {
                    value: true,
                    message: 'Resume/CV is required',
                  },
                }}
              />
              {resumeError && (
                <div className='u-textColor-red u-fontSize-small'>
                  {resumeError}
                </div>
              )}
            </div>
            <div className='col-sm-6'>
              <FormDropzone
                initialValue={formData.coverLetter}
                label='Cover Letter'
                name='coverLetter'
              />
            </div>
          </div>
        </div>

        {applyConsentText ? (
          <>
            <Form.Checkbox
              className='u-marginVertical-16 u-marginLeft-n'
              initialValue={formData.consentProvided}
              label={applyConsentText}
              name='consentProvided'
              rules={{
                validate: validateConsent,
                required: {
                  value: true,
                },
              }}
            />
            {caslCompanyName && privacyUrl ? (
              <div className='u-marginTop-16'>
                <a href={privacyUrl} rel='noopener noreferrer' target='_blank'>
                  View Privacy Policy for {caslCompanyName}
                </a>
              </div>
            ) : null}
          </>
        ) : null}

        <div className='u-flex u-flexJustify-r'>
          <ButtonPrimary isOutline={true} label='Cancel' onClick={onClose} />
          <Form.SubmitButton
            isDisabled={isApplicationLoading || Boolean(resumeError)}
            isLoading={isApplicationLoading}
            label={hasVoluntaryIdentificationStatusEnabled ? 'Next' : 'Submit'}
          />
        </div>
      </LoadingContainer>
    </Form>
  );
};

ApplyProfessionalInfoForm.defaultProps = {
  caslCompanyName: null,
  caslPrivacyPolicyUrl: null,
  formData: null,
  hasVoluntaryIdentificationStatusEnabled: false,
  onClose: null,
};

ApplyProfessionalInfoForm.propTypes = {
  caslCompanyName: PropTypes.string,
  caslPrivacyPolicyUrl: PropTypes.string,
  formData: formDataPropType,
  hasVoluntaryIdentificationStatusEnabled: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

export default ApplyProfessionalInfoForm;
